import {makeStyles} from "@mui/styles";

const FooterStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "5rem"
    },
    logo: {
        height: "2rem",
        width: "auto",
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }

    },
    hideOnSmall: {
        [theme.breakpoints.down("sm")]: {
            display: "none !important"
        }
    },
    logoWrapper: {
        display: "flex",
        justifyContent: "center"
    },
    copyright: {},
    flexItem: {
        flex: 1,
        display: "flex",
        justifyContent: "center",

    },
    link: {
        textDecoration: "none",
    },
    divider: {
        marginRight: ".5rem",
        marginLeft: ".5rem"
    },
    flexbox: {
        display: "flex",
    },
    logoContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%"
    },
    legalStuff: {
        display: "flex",
        justifyContent: "flex-end",
        width: "100%"
    }

}))
export default FooterStyles
