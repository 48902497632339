import React from "react";
import ViewPortWrapper from "../viewPortWrapper/ViewPortWrapper";
import {Grid, Typography, useTheme} from "@mui/material";
import OfferCard from "../OfferCard/OfferCard";
import DesignImage from "../../img/Design.jpg"
import Flow1 from "../../img/flow1.jpg"
import ServerImage from "../../img/server.jpg"
import Homepage from "./Homepage/Homepage";
import Application from "./Application/Application";
import ItCare from "./ItCare/ItCare";
import OffersStyles from "./OffersStyles";

function Offers() {

    const offersStyles = OffersStyles();
    const theme = useTheme();

    const offerings = [
        {
            name: "Homepages",
            subtitle: "Von dem ersten Design zur Veröffentlichung",
            image: DesignImage,
            content: <Homepage/>
        },
        {
            name: "Webanwendungen",
            subtitle: "Verarbeitung und Aufbereitung von Daten",
            image: Flow1,
            content: <Application/>

        },
        {
            name: "IT-Unterstützung",
            subtitle: "Beratung & Betreuung bei IT-Systemen",
            image: ServerImage,
            content: ItCare

        },
    ]

    return (
        <ViewPortWrapper
            height={"60vh"}
            backgroundColor={theme.palette.primary.main}
            contrastColor={theme.palette.primary.contrastText}
        >
            <div className={offersStyles.fullWidth}>
                <Typography variant={"h2"} gutterBottom>Was ich für dich tun kann</Typography>
                <Grid container spacing={3} xs={12} alignContent={"center"} className={offersStyles.fullHeight}>
                    {offerings.map((offer, index) => (
                        <Grid item sm={4} className={offersStyles.fullWidth} key={index}>
                            <OfferCard image={offer.image} subtitle={offer.subtitle} name={offer.name}/>
                        </Grid>
                    ))}

                </Grid>
            </div>
        </ViewPortWrapper>
    )

}

export default Offers;
