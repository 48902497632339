import {makeStyles} from "@mui/styles";

const ViewPortWrapperStyles = makeStyles((theme) => ({

    fullCard: props => ({
        minHeight: "calc(" + props.height + " - 6rem - 6rem)",
        background: props.background,
        color: props.contrastColor,
        display: "flex",
        //paddingLeft: "9rem",
        //paddingRight: "9rem",
        paddingTop: "6rem",
        paddingBottom: "6rem",
        ...props.stylings
    }),

}))
export default ViewPortWrapperStyles;
