import ViewPortWrapperStyles from "./ViewPortWrapperStyles";
import {Container} from "@mui/material";


function ViewPortWrapper(props) {

    const viewPortWrapperStyles = ViewPortWrapperStyles({
        background: props.backgroundColor,
        contrastColor: props.contrastColor,
        height: props.height,
        stylings: props.stylings
    })

    return (
        <div className={viewPortWrapperStyles.fullCard}>
            <Container style={{display: "flex"}}>
                {props.children}
            </Container>
        </div>

    )
}

export default ViewPortWrapper;
