import React from "react"
import {Link} from "react-router-dom";
import {Container, Typography} from "@mui/material";

function DataPolicy() {

    return (
        <Container>
            <Typography variant="h1">Datenschutzerklärung </Typography>
            <Typography variant="body1"><Link to={"/"}>Zurück</Link></Typography>
            <Typography variant="body1">Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der
                EU-Datenschutzgrundverordnung
                (DSGVO), ist:</Typography>
            <Typography variant="body1" gutterBottom>Cedric Rische</Typography>
            <Typography variant="h2">Ihre Betroffenenrechte</Typography>
            <Typography variant="body1">Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie
                jederzeit folgende
                Rechte ausüben:</Typography>
            <ul>
                <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),</li>
                <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
                <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
                <li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch
                    nicht löschen dürfen (Art. 18 DSGVO),
                </li>
                <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
                <li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag
                    mit uns abgeschlossen haben (Art. 20 DSGVO).
                </li>
            </ul>
            <Typography variant="body1">Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit
                Wirkung für die
                Zukunft widerrufen.</Typography>
            <Typography variant="body1">Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden,
                z. B. an die
                zuständige Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche
                Stelle zuständige Behörde.</Typography>
            <Typography variant="body1" gutterBottom>Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen
                Bereich) mit
                Anschrift finden Sie
                unter: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                          target="_blank"
                          rel="nofollow noopener noreferrer">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.
            </Typography>
            <Typography variant="h2">Erfassung allgemeiner Informationen beim Besuch unserer Website</Typography>
            <Typography variant="h3">Art und Zweck der Verarbeitung:</Typography>
            <Typography variant="body1">Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht registrieren
                oder anderweitig
                Informationen übermitteln, werden automatisch Informationen allgemeiner Natur erfasst. Diese
                Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete
                Betriebssystem, den Domainnamen Ihres Internet-Service-Providers, Ihre IP-Adresse und
                ähnliches. </Typography>
            <Typography variant="body1">Sie werden insbesondere zu folgenden Zwecken verarbeitet:</Typography>
            <ul>
                <li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
                <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
                <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
                <li>zu weiteren administrativen Zwecken.</li>
            </ul>
            <Typography variant="body1" gutterBottom>Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu
                ziehen.
                Informationen dieser Art
                werden von uns ggfs. statistisch ausgewertet, um unseren Internetauftritt und die dahinterstehende
                Technik zu optimieren.</Typography>
            <Typography variant="h3">Rechtsgrundlage:</Typography>
            <Typography variant="body1" gutterBottom>Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis
                unseres
                berechtigten Interesses
                an der Verbesserung der Stabilität und Funktionalität unserer Website.</Typography>
            <Typography variant="h3">Empfänger:</Typography>
            <Typography variant="body1" gutterBottom>Empfänger der Daten sind ggf. technische Dienstleister, die für den
                Betrieb und
                die Wartung unserer
                Webseite als Auftragsverarbeiter tätig werden.</Typography>
            <Typography variant="h3">Speicherdauer:</Typography>
            <Typography variant="body1" gutterBottom>Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung
                nicht mehr
                erforderlich sind. Dies
                ist für die Daten, die der Bereitstellung der Webseite dienen, grundsätzlich der Fall, wenn die
                jeweilige Sitzung beendet ist.</Typography>
            <Typography variant="h3">Bereitstellung vorgeschrieben oder erforderlich:</Typography>
            <Typography variant="body1">Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich
                noch vertraglich
                vorgeschrieben. Ohne die IP-Adresse ist jedoch der Dienst und die Funktionsfähigkeit unserer Website
                nicht gewährleistet. Zudem können einzelne Dienste und Services nicht verfügbar oder eingeschränkt
                sein. Aus diesem Grund ist ein Widerspruch ausgeschlossen. </Typography>
            <Typography variant="h2">Cookies</Typography>
            <Typography variant="h3">Art und Zweck der Verarbeitung:</Typography>
            <Typography variant="body1">Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“. Bei Cookies
                handelt es sich um
                kleine Textdateien, die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden,
                wenn Sie unsere Webseite besuchen. </Typography>
            <Typography variant="body1">Hierdurch erhalten wir bestimmte Daten wie z. B. IP-Adresse, verwendeter Browser
                und
                Betriebssystem.</Typography>
            <Typography variant="body1">Cookies können nicht verwendet werden, um Programme zu starten oder Viren auf
                einen Computer zu
                übertragen. Anhand der in Cookies enthaltenen Informationen können wir Ihnen die Navigation
                erleichtern und die korrekte Anzeige unserer Webseiten ermöglichen.</Typography>
            <Typography variant="body1">In keinem Fall werden die von uns erfassten Daten an Dritte weitergegeben oder
                ohne Ihre Einwilligung
                eine Verknüpfung mit personenbezogenen Daten hergestellt.</Typography>
            <Typography variant="body1" gutterBottom>Natürlich können Sie unsere Website grundsätzlich auch ohne Cookies
                betrachten.
                Internet-Browser sind
                regelmäßig so eingestellt, dass sie Cookies akzeptieren. Im Allgemeinen können Sie die Verwendung
                von Cookies jederzeit über die Einstellungen Ihres Browsers deaktivieren. Bitte verwenden Sie die
                Hilfefunktionen Ihres Internetbrowsers, um zu erfahren, wie Sie diese Einstellungen ändern können.
                Bitte beachten Sie, dass einzelne Funktionen unserer Website möglicherweise nicht funktionieren,
                wenn Sie die Verwendung von Cookies deaktiviert haben.</Typography>
            <Typography variant="h3">Speicherdauer und eingesetzte Cookies:</Typography>
            <Typography variant="body1">Soweit Sie uns durch Ihre Browsereinstellungen oder Zustimmung die Verwendung
                von Cookies erlauben,
                können folgende Cookies auf unseren Webseiten zum Einsatz kommen:</Typography>
            <Typography variant="body1">AUTH_SESSION_ID - Session
                <br/>KEYCLOAK_IDENTITY - Session
                <br/>KEYCLOAK_SESSION - ein Tag</Typography>
            <Typography variant="body1">Soweit diese Cookies (auch) personenbezogene Daten betreffen können, informieren
                wir Sie darüber in
                den folgenden Abschnitten.</Typography>
            <Typography variant="body1" gutterBottom>Sie können über Ihre Browsereinstellungen einzelne Cookies oder den
                gesamten
                Cookie-Bestand löschen.
                Darüber hinaus erhalten Sie Informationen und Anleitungen, wie diese Cookies gelöscht oder deren
                Speicherung vorab blockiert werden können. Je nach Anbieter Ihres Browsers finden Sie die
                notwendigen Informationen unter den nachfolgenden Links:</Typography>
            <ul>
                <li>Mozilla Firefox: <a
                    href="https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen"
                    target="_blank"
                    rel="nofollow noopener noreferrer">https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen</a>
                </li>
                <li>Internet Explorer: <a
                    href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies"
                    target="_blank"
                    rel="nofollow noopener noreferrer">https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies</a>
                </li>
                <li>Google Chrome: <a href="https://support.google.com/accounts/answer/61416?hl=de" target="_blank"
                                      rel="nofollow noopener noreferrer">https://support.google.com/accounts/answer/61416?hl=de</a>
                </li>
                <li>Opera: <a href="http://www.opera.com/de/help" target="_blank"
                              rel="nofollow noopener noreferrer">http://www.opera.com/de/help</a></li>
                <li>Safari: <a href="https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE"
                               target="_blank"
                               rel="nofollow noopener noreferrer">https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE</a>
                </li>
            </ul>
            <Typography variant="h2">Registrierung auf unserer Website</Typography>
            <Typography variant="h3">Art und Zweck der Verarbeitung:</Typography>
            <Typography variant="body1" gutterBottom>Bei der Registrierung für die Nutzung unserer personalisierten
                Leistungen werden
                einige
                personenbezogene Daten erhoben, wie Name, Anschrift, Kontakt- und Kommunikationsdaten (z. B.
                Telefonnummer und E-Mail-Adresse). Sind Sie bei uns registriert, können Sie auf Inhalte und
                Leistungen zugreifen, die wir nur registrierten Nutzern anbieten. Angemeldete Nutzer haben zudem die
                Möglichkeit, bei Bedarf die bei Registrierung angegebenen Daten jederzeit zu ändern oder zu löschen.
                Selbstverständlich erteilen wir Ihnen darüber hinaus jederzeit Auskunft über die von uns über Sie
                gespeicherten personenbezogenen Daten.</Typography>
            <Typography variant="h3">Rechtsgrundlage:</Typography>
            <Typography variant="body1">Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf
                Grundlage einer
                Einwilligung des Nutzers (Art. 6 Abs. 1 lit. a DSGVO).</Typography>
            <Typography variant="body1" gutterBottom>Dient die Registrierung der Erfüllung eines Vertrages, dessen
                Vertragspartei die
                betroffene Person
                ist oder der Durchführung vorvertraglicher Maßnahmen, so ist zusätzliche Rechtsgrundlage für die
                Verarbeitung der Daten Art. 6 Abs. 1 lit. b DSGVO.</Typography>
            <Typography variant="h3">Empfänger:</Typography>
            <Typography variant="body1">Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und
                die Wartung unserer
                Website als Auftragsverarbeiter tätig werden.</Typography>
            <Typography variant="h3">Speicherdauer:</Typography>
            <Typography variant="body1" gutterBottom>Daten werden in diesem Zusammenhang nur verarbeitet, solange die
                entsprechende
                Einwilligung vorliegt.
                Danach werden sie gelöscht, soweit keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Zur
                Kontaktaufnahme in diesem Zusammenhang nutzen Sie bitte die am Ende dieser Datenschutzerklärung
                angegebenen Kontaktdaten.</Typography>
            <Typography variant="h3">Bereitstellung vorgeschrieben oder erforderlich:</Typography>
            <Typography variant="body1" gutterBottom>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt
                freiwillig, allein auf
                Basis Ihrer
                Einwilligung. Ohne die Bereitstellung Ihrer personenbezogenen Daten können wir Ihnen keinen Zugang
                auf unsere angebotenen Inhalte und Leistungen gewähren.</Typography>
            <Typography variant="h2">Verwendung von Scriptbibliotheken (Google Webfonts)</Typography>
            <Typography variant="h3">Art und Zweck der Verarbeitung:</Typography>
            <Typography variant="body1" gutterBottom>Um unsere Inhalte browserübergreifend korrekt und grafisch
                ansprechend
                darzustellen, verwenden wir
                auf dieser Website „Google Web Fonts“ der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA
                94043, USA; nachfolgend „Google“) zur Darstellung von Schriften.</Typography>
            <Typography variant="body1">Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie hier: <a
                href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a></Typography>
            <Typography variant="h3">Rechtsgrundlage:</Typography>
            <Typography variant="body1" gutterBottom>Rechtsgrundlage für die Einbindung von Google Webfonts und dem
                damit verbundenen
                Datentransfer zu
                Google ist Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). </Typography>
            <Typography variant="h3">Empfänger:</Typography>
            <Typography variant="body1" gutterBottom>Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken löst
                automatisch eine
                Verbindung zum
                Betreiber der Bibliothek aus. Dabei ist es theoretisch möglich – aktuell allerdings auch unklar ob
                und ggf. zu welchen Zwecken – dass der Betreiber in diesem Fall Google Daten erhebt.</Typography>
            <Typography variant="h3">Speicherdauer:</Typography>
            <Typography variant="body1">Wir erheben keine personenbezogenen Daten, durch die Einbindung von Google
                Webfonts.</Typography>
            <Typography variant="body1" gutterBottom>Weitere Informationen zu Google Web Fonts finden Sie unter <a
                href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a> und in
                der Datenschutzerklärung von Google: <a
                    href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.
            </Typography>
            <Typography variant="h3">Drittlandtransfer:</Typography>
            <Typography variant="body1" gutterBottom>Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US
                Privacy Shield
                unterworfen <a
                    href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>.
            </Typography>
            <Typography variant="h3">Bereitstellung vorgeschrieben oder erforderlich:</Typography>
            <Typography variant="body1" gutterBottom>Die Bereitstellung der personenbezogenen Daten ist weder
                gesetzlich, noch
                vertraglich vorgeschrieben.
                Allerdings kann ggfs. die korrekte Darstellung der Inhalte durch Standardschriften nicht möglich
                sein.</Typography>
            <Typography variant="h3">Widerruf der Einwilligung:</Typography>
            <Typography variant="body1" gutterBottom>Zur Darstellung der Inhalte wird regelmäßig die Programmiersprache
                JavaScript
                verwendet. Sie können
                der Datenverarbeitung daher widersprechen, indem Sie die Ausführung von JavaScript in Ihrem Browser
                deaktivieren oder einen JavaScript-Blocker installieren. Bitte beachten Sie, dass es hierdurch zu
                Funktionseinschränkungen auf der Website kommen kann.</Typography>
            <Typography variant="h2">SSL-Verschlüsselung</Typography>
            <Typography variant="body1" gutterBottom>Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen,
                verwenden wir dem
                aktuellen Stand der
                Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.</Typography>
            <Typography variant="h2">Änderung unserer Datenschutzbestimmungen</Typography>
            <Typography variant="body1" gutterBottom>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit
                sie stets den
                aktuellen
                rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der
                Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch
                gilt dann die neue Datenschutzerklärung.</Typography>
            <Typography variant="h2">Fragen an den Datenschutzbeauftragten</Typography>
            <Typography variant="body1">Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder
                wenden Sie sich
                direkt an die für den Datenschutz verantwortliche Person in unserer Organisation:</Typography>
            <Typography variant="body1">webmaster@cedsoft.de</Typography>
            <Typography variant="body1"><em>Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt, den
                Experten für <a
                    href="https://www.activemind.de/datenschutz/datenschutzhinweis-generator/" target="_blank"
                    rel="noopener noreferrer">externe Datenschutzbeauftragte</a> (Version
                #2019-04-10).</em></Typography>

        </Container>
    )

}

export default DataPolicy
