import React from 'react';
import ReactDOM from 'react-dom';
import './css/theme.css';
import './css/layout.css';
import "typeface-oxygen";
import App from './App';

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
    , document.getElementById('root'));
