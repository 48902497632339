import React from "react";
import {Typography} from "@mui/material";

function Homepage() {

    return (
        <>
            <Typography variant={"h1"} component={"h2"} gutterBottom>
                Eine eigene Homepage
            </Typography>
            <Typography variant={"h6"} gutterBottom>
                Ein absolutes Muss für die heutige Zeit. Eine Homepage wird benötigt, um im Internet gefunden zu werden
                und sich so von Konkurrenten abzugrenzen.
            </Typography>
        </>
    )

}

export default Homepage;
