import React from "react";
import tvjahn from '../../img/projects/tvjahn.jpg';
import MaximumPerformanceImage from '../../img/projects/maximumPerformance.jpg';
import EnrollImage from '../../img/projects/EnrollHub.png';
import StarwitImage from '../../img/projects/starwitBackground.jpg';
import ViewPortWrapper from "../viewPortWrapper/ViewPortWrapper";
import ProjectCard from "../ProjectCard/ProjectCard"
import {Grid, Typography} from "@mui/material";
import ProjectStyles from "./ProjectStyles";

function Projects() {

    const projectStyles = ProjectStyles();

    const projects = [
        {
            name: "Enroll Hub",
            description: "Anmeldungen für Vereine",
            picture: EnrollImage,
            link: "https://enroll-hub.de/"
        },
        {
            name: "Starwit Technologies GmbH",
            description: "Freelance Arbeit an verschiedenen Projekten",
            picture: StarwitImage,
            link: "https://starwit-technologies.de/"
        },
        {
            name: "Maximum Performance",
            description: "Unternehmensseite",
            picture: MaximumPerformanceImage,
            link: "https://mperformance.net"
        },
        {
            name: "TV Jahn Wolfsburg",
            description: "Tennis Jugend Anmeldung",
            picture: tvjahn,
            link: "https://tennis.tvjahn-wolfsburg.de"
        }
    ];


    return (
        <ViewPortWrapper height={"70vh"}>
            <div style={{width: "100%"}}>
                <Typography gutterBottom variant={"h2"} className={projectStyles.title}>Projekte</Typography>
                <Grid container spacing={3} xs={12}>
                    {projects.map((element, index) => (
                        <Grid item xs={12} key={index} className={projectStyles.cardGrid}>
                            <ProjectCard
                                image={element.picture}
                                name={element.name}
                                description={element.description}
                                link={element.link}
                            />
                        </Grid>
                    ))}
                </Grid>
            </div>
        </ViewPortWrapper>
    );
}

export default Projects;
