import {makeStyles} from "@mui/styles";

const OfferStyles = makeStyles(theme => ({

    offers: {
        display: "flex",
        justifyContent: ""
    },
    appBar: {
        background: theme.palette.primary.main
    },
    fullWidth: {
        width: "100%"
    },
    fullHeight: {
        height: "100%"
    }

}))
export default OfferStyles;
