import React from "react";
import {Card, CardContent, Typography} from "@mui/material";
import OfferCardStyles from "./OfferCardStyles";

function OfferCard(props) {

    const offerCardStyles = OfferCardStyles({
        backgroundImage: props.image
    });

    return (
        <Card className={`${offerCardStyles.card}`} elevation={5}>
            <CardContent className={offerCardStyles.action}>
                <Typography variant={"h5"} component={"p"}>{props.name}</Typography>
                <Typography variant={"subtitle1"} component={"p"}>{props.subtitle}</Typography>
            </CardContent>
        </Card>
    )

}

export default OfferCard;
