import {makeStyles} from "@mui/styles";

const OfferCardStyles = makeStyles(theme => ({
    card: props => ({
        backgroundImage: `linear-gradient(to top, rgba(0,0,0,.75), rgba(0,0,0,0)),url(${props.backgroundImage})`, /* W3C */
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "30rem",
    }),
    action: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "start",
        color: theme.palette.primary.contrastText,
        textDecoration: "none",
        textAlign: "left",
        height: "calc(100% - 3rem)",
        padding: "1.5rem"
    },
    image: {
        height: "10rem",
        backgroundSize: "cover",
        width: "5rem"
    }


}))
export default OfferCardStyles;
