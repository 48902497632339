import {makeStyles} from "@mui/styles";

const ContactStyles = makeStyles(theme => ({

    fullWidth: {
        width: "100%"
    }

}))
export default ContactStyles;
