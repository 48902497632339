import {createTheme, responsiveFontSizes} from "@mui/material";

const CedSoftTheme = createTheme({
    palette: {
        type: "light",

        primary: {
            //main: "#263238",
            //dark: "#222c32",
            main: "#223240",
            dark: "#1e2c39",
            mainGradient: `linear-gradient(160deg, #223240, #0554F2)`,
            //mainGradient: `linear-gradient(160deg, rgba(38, 50, 56, 1), rgba(19, 87, 127, 1))`,
            //mainGradient: `linear-gradient(160deg, rgba(38, 50, 56, 1) 0%, rgba(31, 64, 82, 1) 43%, rgba(28, 69, 91, 1) 60%, rgba(27, 72, 96, 1) 74%, rgba(26, 74, 100, 1) 80%, rgba(26, 75, 101, 1) 83%, rgba(25, 76, 104, 1) 87%, rgba(19, 87, 127, 1) 100%)`,
            contrastText: "#f5f5f5"
        },
        secondary: {
            main: '#0554F2',
            contrastText: "#fff"
        },
        background: {
            default: "#fafafa"
        }
    },
    typography: {
        fontFamily: "Oxygen, Arial"
    },

    components: {
        MuiPaper: {
            styleOverrides: {
                rounded: {
                    borderRadius: "1rem"
                }
            }
        },
    },
});
export default responsiveFontSizes(CedSoftTheme);
