import React from "react";
import ViewPortWrapper from "../viewPortWrapper/ViewPortWrapper";
import {
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Typography,
} from "@mui/material";
import {GitHub, LinkedIn, Mail, OpenInNew} from "@mui/icons-material";
import WhoAmIStyles from "./WhoAmIStyles";

const socials = [
    {
        icon: <Mail/>,
        link: "mailto:cedric.rische@cedsoft.de",
        text: "cedric.rische@cedsoft.de"
    },
    {
        icon: <LinkedIn/>,
        link: "https://linkedin.com/in/cedric-rische/",
        text: "cedric-rische",
    },
    {
        icon: <GitHub/>,
        link: "https://github.com/DerCed",
        text: "DerCed"
    }
];

function WhoAmI() {

    const whoAmIStyles = WhoAmIStyles();

    function calculateTimeDifference(dob) {
        const diff_ms = Date.now() - dob.getTime();
        const age_dt = new Date(diff_ms);

        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }

    function calculateAge() {
        return calculateTimeDifference(new Date(1999, 11, 0))
    }

    function calculateExperienceTime() {
        return calculateTimeDifference(new Date(2014, 6, 15))
    }

    return (
        <ViewPortWrapper>

            <Grid container spacing={3} xs={12}>
                <Grid item md={8}>
                    <Typography variant={"h1"} component={"p"}>Hi,</Typography>
                    <Typography variant={"h1"} component={"p"}>ich bin Cedric.</Typography>
                    <Typography variant={"h3"} component={"p"} gutterBottom>Ich bin ein Website Entwickler.</Typography>
                    <Typography variant={"h6"} component={"p"}>
                        Ich bin {calculateAge()} Jahre alt und lebe in Wolfsburg, Deutschland.
                    </Typography>
                    <Typography variant={"h6"} component={"p"} gutterBottom>
                        Seit {calculateExperienceTime()} Jahren beschäftige ich mich mit Computern und dem erstellen von
                        Websites.
                    </Typography>
                    <Typography variant={"body1"} gutterBottom>
                        Mein Ziel ist es, dein Leben im Bereich von Websites oder IT ein Stück leichter zu machen.
                    </Typography>

                    <Typography variant={"body1"} gutterBottom className={whoAmIStyles.hearFromYou}>
                        Ich freue mich von dir zu hören!
                    </Typography>
                    <Typography variant={"h5"} component={"p"} gutterBottom className={whoAmIStyles.name}>
                        Cedric
                    </Typography>
                </Grid>
                <Grid item md={4}
                      style={{width: "100%", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    <Paper elevation={5} className={whoAmIStyles.portrait}/>
                    <List width={"100%"}>
                        {socials.map((social, index) => (
                                <ListItem key={index}>
                                    <ListItemAvatar>
                                        {social.icon}
                                    </ListItemAvatar>
                                    <ListItemText
                                        style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "..."}}
                                        primary={social.text}
                                    />
                                    <ListItemSecondaryAction>
                                        <a href={social.link} target={"_blank"} rel={"nofollow noopener noreferrer"}
                                           aria-label={"Link to " + social.text}>
                                            <OpenInNew className={whoAmIStyles.socialIcons}/>
                                        </a>
                                    </ListItemSecondaryAction>
                                </ListItem>

                            )
                        )}

                    </List>
                </Grid>
            </Grid>

        </ViewPortWrapper>

    );
}

export default WhoAmI;
