import {makeStyles} from "@mui/styles";
import me from "../../img/me.jpg";

const WhoAmIStyles = makeStyles(theme => ({
    socialIcons: {
        color: theme.palette.text.primary
    },
    portrait: {
        background: `url(${me})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "20rem",
        width: "100%",
        marginBottom: theme.spacing(3)
    },
    name: {
        marginTop: theme.spacing(4)
    },
    hearFromYou: {
        marginTop: theme.spacing(2)
    }
}));
export default WhoAmIStyles;
