import React from "react";
import ViewPortWrapper from "../viewPortWrapper/ViewPortWrapper";
import {Button, Grid, Link, Typography, useTheme} from "@mui/material";
import {MailOutline} from "@mui/icons-material";
import ContactStyles from "./ContactStyles";

function Contact() {

    const theme = useTheme();
    const contactStyles = ContactStyles()
    return (
        <ViewPortWrapper height={"20vh"} backgroundColor={theme.palette.primary.main}
                         contrastColor={theme.palette.primary.contrastText}>
            <Grid container alignItems={"center"}>
                <Grid item sm={6}>
                    <Typography variant={"h2"} gutterBottom>
                        Hast Du Interesse an einer Zusammenarbeit?
                    </Typography>
                </Grid>
                <Grid item sm={6} className={contactStyles.fullWidth}>
                    <Button component={Link} href={"mailto:cedricrische@cedsoft.de"} color={"secondary"}
                            variant={"contained"} fullWidth startIcon={<MailOutline/>}
                            size={"large"} aria-label={"Contact via E-Mail"}>E-Mail</Button>
                </Grid>

            </Grid>
        </ViewPortWrapper>
    )

}

export default Contact;
