import {makeStyles} from "@mui/styles";

const ProjectStyles = makeStyles(theme => ({

    root: {
        width: "100%"
    },
    title: {
        marginBottom: theme.spacing(6)
    },
    cardGrid: {
        marginBottom: theme.spacing(4)
    }


}));
export default ProjectStyles;
