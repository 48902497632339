import React, {Component} from "react";
import Welcome from "../components/welcome/Welcome";
import WhoAmI from "../components/whoAmI/WhoAmI";
import Projects from "../components/projects/Projects";
import Footer from "../components/footer/Footer";
import Offers from "../components/offers/Offers";
import Contact from "../components/Contact/Contact";

class Home extends Component {

    render() {
        return (
            <React.Fragment>
                <Welcome/>
                <WhoAmI/>
                <Offers/>
                <Projects/>
                <Contact/>
                <Footer/>
            </React.Fragment>

        );
    }

}

export default Home;