import React from "react";
import {Card, CardActionArea, Grid, Link, Typography} from "@mui/material";
import ProjectCardStyles from "./ProjectCardStyles";

function ProjectCard(props) {

    const projectCardStyles = ProjectCardStyles({
        image: props.image
    });

    return (

        <Grid container spacing={5}>
            <Grid item sm={6} className={projectCardStyles.firstGrid}>
                <Card elevation={5}>
                    <CardActionArea className={projectCardStyles.paper} component={Link}
                                    aria-label={"Link to " + props.name} href={props.link} target={"_blank"}
                                    rel={"nofollow noopener noreferrer"}/>
                </Card>
            </Grid>
            <Grid item sm={6}>
                <Typography variant={"h3"} gutterBottom>{props.name}</Typography>
                <Typography variant={"subtitle1"} component={"p"}>{props.description}</Typography>
            </Grid>
        </Grid>
    )

}

export default ProjectCard;
