import {makeStyles} from "@mui/styles";

const WelcomeStyles = makeStyles((theme) => ({
    welcome: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    logo: {
        height: "auto",
        width: "10rem"
    },
    arrowWrapper: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        fontSize: "10rem"
    },
    arrow: {
        fontSize: "4rem"
    },
}))

export default WelcomeStyles;
