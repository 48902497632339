import React, {useEffect, useState} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom"
import Home from "./pages/Home";
import DataPolicy from "./pages/DataPolicy";
import Imprint from "./pages/Imprint";
import {Button, Snackbar, ThemeProvider} from "@mui/material";
import CedSoftTheme from "./assets/themes/CedSoftTheme";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

function App() {

    const [newVersionAvailable, setNewVersionAvailable] = useState(false);
    const [waitingWorker, setWaitingWorker] = useState({});

    function onServiceWorkerUpdate(registration) {
        setNewVersionAvailable(true);
        setWaitingWorker(registration.waiting);
    }

    function updateServiceWorker() {
        console.log(waitingWorker)
        waitingWorker.postMessage({type: "SKIP_WAITING"});
        setNewVersionAvailable(false);
        window.location.reload(true);
    }

    useEffect(() => {
        serviceWorkerRegistration.register({onUpdate: onServiceWorkerUpdate});
    }, [])

    function handleUpdateClose() {
        setNewVersionAvailable(false);
    }

    function renderSnackbar() {
        return (
            <Snackbar
                color={"primary"}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={newVersionAvailable}
                onClose={handleUpdateClose}
                message={"Es ist eine neue Version verfügbar!"}
                key={"new-version-available"}
                action={<Button size={"small"} color={"default"}
                                onClick={updateServiceWorker}>Neu laden</Button>}
            />
        )
    }


    return (
        <ThemeProvider theme={CedSoftTheme}>
            <Router>
                <Switch>
                    <Route exact path={"/"} component={Home}/>
                    <Route path={"/datapolicy"} component={DataPolicy}/>
                    <Route path={"/imprint"} component={Imprint}/>
                </Switch>
            </Router>
            {renderSnackbar()}
        </ThemeProvider>
    );
}

export default App;
