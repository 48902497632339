import React from "react";
import cedsoftWhite from '../../img/cedsoftMainWhite.svg';
import {Link} from "react-router-dom";
import FooterStyles from "./FooterStyles";
import {Container, Grid, Typography, useMediaQuery, useTheme} from "@mui/material";


function Footer() {

    const footerStyles = FooterStyles();
    const theme = useTheme();

    const gridItemSize = useMediaQuery(theme.breakpoints.down("sm")) ? 6 : 4

    return (
        <div className={footerStyles.root}>
            <Container>
                <Grid container spacing={0} className={footerStyles.rootGrid} alignItems={"center"}>
                    <Grid item xs={gridItemSize}>
                        <Typography variant={"body2"} className={`${footerStyles.copyright}`}>
                            © {new Date().getFullYear()} CedSoft
                        </Typography>
                    </Grid>
                    <Grid item xs={gridItemSize}
                          className={`${footerStyles.hideOnSmall} ${footerStyles.logoContainer}`}>
                        <img className={footerStyles.logo} src={cedsoftWhite} alt="CedSoft"/>
                    </Grid>
                    <Grid item xs={gridItemSize} className={footerStyles.legalStuff}>
                        <div className={`${footerStyles.flexbox} ${footerStyles.flexbox}`}>
                            <Typography variant={"body2"} component={Link} to={"/imprint"} color={"inherit"}
                                        className={footerStyles.link}>Impressum</Typography>
                            <Typography variant={"body2"} className={footerStyles.divider}>|</Typography>
                            <Typography component={Link} variant={"body2"} to={"/datapolicy"} color={"inherit"}
                                        className={footerStyles.link}>Datenschutz</Typography>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )

}

export default Footer;
