import React from "react";
import CedSoftLogo from "../../img/cedsoftMainWhite.svg";
import ViewPortWrapper from "../viewPortWrapper/ViewPortWrapper";
import {Typography, useTheme} from "@mui/material";
import WelcomeStyles from "./WelcomeStyles";
import {KeyboardArrowDown} from "@mui/icons-material";
import DeskBlue from "../../img/deskBlue.jpg";


function Welcome() {

    const theme = useTheme();
    const welcomeStyles = WelcomeStyles();

    return (
        <ViewPortWrapper height={"80vh"}
                         backgroundColor={`linear-gradient(to right, rgba(0,0,0,.6), rgba(0,0,0,.3)), url(${DeskBlue})`}
                         contrastColor={theme.palette.primary.contrastText}
                         stylings={{
                             backgroundSize: "cover",
                             backgroundPosition: "center center",
                             backgroundRepeat: "no-repeat"

                         }}
        >
            <div className={welcomeStyles.welcome} style={{transform: "scale(1,1)"}}>
                <img src={CedSoftLogo} className={welcomeStyles.logo} alt="CedSoft Logo"/>
                <div className={welcomeStyles.title}>
                    <Typography variant={"h1"}><b>Ced</b>Soft</Typography>
                    <Typography variant={"h5"} component={"h2"}>Website Development</Typography>
                </div>

                <div className={welcomeStyles.arrowWrapper}>
                    <KeyboardArrowDown fontSize={"large"} classes={{fontSizeLarge: welcomeStyles.arrow}}/>
                </div>
            </div>
        </ViewPortWrapper>

    );
}

export default Welcome;
