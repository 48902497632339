import {makeStyles} from "@mui/styles";

const ProjectCardStyles = makeStyles(theme => ({

    paper: props => ({
        backgroundPosition: "center top",
        backgroundImage: `url(${props.image})`,
        backgroundSize: "cover",
        height: "17rem",
        width: "100%"
    }),
    firstGrid: {
        width: "100%"
    }
}))
export default ProjectCardStyles;
